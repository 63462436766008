




























































import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import MyTinymce from '@/components/Editor/index.vue'
import { uploadFile } from '@/api/common'
import Share from '@/components/Share/index.vue'
import {
  createYearPlan,
  getYearPlanDetail,
  editYearPlanDetail,
} from '@/api/year'
@Component({
  components: {
    MyTinymce,
    Share
  },
  beforeRouteLeave(this: any, to: any, from: any, next: any) {
    if (
      from.path === '/year/addYearPlan' &&
      to.path !== '/yearPlan/index' &&
      to.path !== '/year/showYearPlan'
    ) {
      if (this.isChange()) {
        this.$confirm({
          cancelText: '放弃',
          okText: '继续编辑',
          content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
          onCancel: () => {
            this.showPage = false
            this.$nextTick(() => {
            next()
          })
          },
          onOk: () => {
            next(false)
          },
        })
      } else {
        this.showPage = false
            this.$nextTick(() => {
            next()
          })
      }
    } else {
      this.showPage = false
            this.$nextTick(() => {
            next()
          })
    }
  },
})
export default class addYearPlan extends Vue {
  formData: any = {
    title: '',
    year: new Date().getFullYear(),
    yearContent: '',
    businessAttachmentList: [],
    shareList:[]
  }
  id: any = ''
  oldForm: any = {
    title: '',
    year: '',
    yearContent: '',
    businessAttachmentList: [],
  }
  currentIndex: number = new Date().getFullYear()
  currentYear: number = new Date().getFullYear()
  nextYear: number = new Date().getFullYear() + 1
  fileList: object[] = []
  year: any = ''
  private submitLoading = false
  private showPage = true
  mounted() {
    if (this.$route.query.year) {
      this.year = this.$route.query.year
      this.currentIndex = +this.$route.query.year
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.getPlanDetail(this.year)
      }
    } else if (this.$route.query.createYear) {
      this.year = this.$route.query.createYear
      this.currentIndex = +this.$route.query.createYear
      this.formData.year = +this.$route.query.createYear
    }
  }

  isChange() {
    // 只要一个有值改变并且没有点击提交，拦截
    return (
      this.oldForm.title !== this.formData.title ||
      this.oldForm.yearContent !== this.formData.yearContent ||
      JSON.stringify(this.oldForm.businessAttachmentList) !==
        JSON.stringify(this.formData.businessAttachmentList)
    )
  }
  addShareList(e:any){
    this.formData.shareList = e
  }
  async getPlanDetail(year: any) {
    const [err, data] = await getYearPlanDetail(year)
    if (data) {
      this.formData = data
      if (data.businessAttachmentList === null) {
        this.formData.businessAttachmentList = []
      }
      if (data.shareList === null) {
        this.formData.shareList = []
      }
      this.oldForm.title = this.formData.title
      this.oldForm.year = this.formData.year
      this.oldForm.yearContent = this.formData.yearContent
      this.oldForm.businessAttachmentList = this.formData.businessAttachmentList
    } else {
      this.formData = {
        title: '',
        year: '',
        yearContent: '',
        businessAttachmentList: [],
      }
      this.oldForm.title = this.formData.title
      this.oldForm.year = this.formData.year
      this.oldForm.yearContent = this.formData.yearContent
      this.oldForm.businessAttachmentList = this.formData.businessAttachmentList
    }
  }
  async commitForm() {
    if (!this.formData.yearContent) {
      this.$message.warning('内容不能为空')
      return
    }
    this.submitLoading = true
    if (this.id) {
      const [err] = await editYearPlanDetail(this.id, this.formData)
      this.submitLoading = false
      if (!err) {
        this.$nextTick(() => {
          this.$message.success('编辑成功')
          this.showPage = false
          this.$router.push({
            path: '/year/showYearPlan',
            query: { year: this.year },
          })
        })
      }
    } else {
      const [err] = await createYearPlan(this.formData)
      this.submitLoading = false
      if (!err) {
        this.$nextTick(() => {
          this.$message.success('添加成功')
          this.showPage = false
          this.$router.push({
            path: '/year/showYearPlan',
            query: { year: this.year },
          })
        })
      }
    }
  }
  async selectYear(e: number) {
    var that = this
    if (this.isChange()) {
      this.$confirm({
        cancelText: '放弃',
        okText: '继续编辑',
        content: '系统检测到您还未提交，确定放弃已编辑的内容吗？',
        onOk() {},
        async onCancel() {
          that.currentIndex = e
          if (that.currentIndex === that.currentYear) {
            const year = new Date().getFullYear()
            await that.getPlanDetail(year)
            that.id = that.formData.id
            that.formData.year = new Date().getFullYear()
            that.year = new Date().getFullYear()
          } else {
            const year = new Date().getFullYear() + 1
            await that.getPlanDetail(year)
            that.id = that.formData.id
            that.formData.year = new Date().getFullYear() + 1
            that.year = new Date().getFullYear() + 1
          }
        },
      })
    } else {
      that.currentIndex = e
      if (that.currentIndex === that.currentYear) {
        const year = new Date().getFullYear()
        await that.getPlanDetail(year)
        that.id = that.formData.id
        that.formData.year = new Date().getFullYear()
        that.year = new Date().getFullYear()
      } else {
        const year = new Date().getFullYear() + 1
        await that.getPlanDetail(year)
        that.id = that.formData.id
        that.formData.year = new Date().getFullYear() + 1
        that.year = new Date().getFullYear() + 1
      }
    }
  }
}
